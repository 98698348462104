import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import s3prefixUrl from '../../../icons/awsS3prefixUrl';

/**
 * Using the following 2 packages
 * https://github.com/remarkjs/react-markdown : render markdown
 * https://github.com/remarkjs/remark-gfm : can render tables & html
 **/

const LaunchWebsiteWithAWS = () => {
  const styles = {
    textAlign: 'start',
    height: '100%',
    width: '100%',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const innerStyles = {
    // paddingLeft: '1.5%',
    // paddingRight: '1%',
    // paddingTop: '100px',
    // textAlign: 'start',
    // display: 'flex',
    // justifyContent:'center',
    // alignItems:'center'
  }

  const imageStyle = {
    float: 'left',
    // width: '142px',
    // height: '142px',
  }

  const awsS3url = s3prefixUrl.awsImagePath;

  return (
    <Grid>
      <Box>
        <div className="content" style={styles}>
          <div style={innerStyles}>
            <h1>Launch a simple static website with AWS</h1>
            <hr />
            <h4>AWS account</h4>
            <p>First create a free AWS account and sign in here:
              <a href="https://signin.aws.amazon.com"> https://signin.aws.amazon.com</a>
            </p>
            <br />
            <h4>AWS Amplify</h4>
            <p>Search AWS Amplify in the services search bar then host your website in just few more step!</p>
            <img src={awsS3url + '/AWS_amplify_1.png'} />
            <br />
            <br />
            <p id="section1"> 1) AWS Amplify supports React, Vue and JS framework! Click Get Started to link your project!</p>
            <img src={awsS3url + '/AWS_amplify_2.png'} />
            <br />
            <p> Note:
              <br />
              Before deploying your website, make sure you double check the region you want to deploy to!
              <br />
              As chosen wrong region to host your website can cause high latency.
              <br />
              Check the region in top-right corner once you are in Amplify.
            </p>
            <img src={awsS3url + '/AWS_region.png'} />
            <br />
            <br />
            <p id="section2"> 2) Connect your Git repository then link your project and the branch you want to deploy!</p>
            <img src={awsS3url + '/AWS_amplify_3.png'} />
            <br />
            <br />
            <p id="section3"> 3) After linked your project branch, AWS Amplify can auto detect the app setting,
              double check it and hit Next.
              <br />
              Before you deploy, you need to review the repository detail & App setting,
              you can then Save & deploy!
              <br />
              It takes some time for it to clone your repository to build, deploy &
              verify your app on different mobile resolutions!
              <br />
            </p>
            <img src={awsS3url + '/AWS_amplify_5.png'} />
            <br />
            <br />
            <p id="section4"> 4) After successful deployment, your app will deploy to AWS amplify app domain.
              <br />
              The website will re-build & re-deploy if you made any change to the project branch you have connected (e.g. master).
              <br />
              <br />
              You can add & connect your own domain by going to AWS Amplify Domain management.
              <br />
              If you do not have a personal domain yet, it is easy to register one with AWS Route53:
              <br />
              <HashLink to="/aws-route53#top"> Register Domain with AWS Route53</HashLink>
              <br />
            </p>
            <img src={awsS3url + '/AWS_amplify_6.png'} />
            <br />
            <br />
            <p id="section5"> 5) If you already have a domain to connect or after finish register one, you are ready to connect!
              <br />
              Click Add domain, then enter your domain name, adds in any subdomains then hit save!
              <br />
            </p>
            <img src={awsS3url + '/AWS_amplify_7.png'} />
            <br />
            <br />
            <p id="section6"> 6) It will take a while for SSL verification & configuration, once its active, your website is ready to go~
              <br />
            </p>
            <img src={awsS3url + '/AWS_amplify_8.png'} />
            <br />
            <br />
            <br />
          </div>
        </div >
      </Box>
    </Grid>
  )
}

export default LaunchWebsiteWithAWS;
