import React from 'react';
import './App.css';

/** Component Imports */
import LaunchWebsiteWithAWS from './components/documents/AWS/LaunchWebsiteWithAWS';
import AwsRoute53 from './components/documents/AWS/AwsRoute53';
import AwsBudgetAlert from './components/documents/AWS/AwsBudgetAlert';
import SetUpWsl from './components/documents/codingEnvironment/SetUpWsl';

import ResponsiveAppBar from './components/navbar/AppBar';
import SideDrawer from './components/navbar/SideDrawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

/** Other Dependencies */
import {
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  const mainPageRoutes = (
    <Routes>
      <Route path="/" element={<LaunchWebsiteWithAWS />} />
      <Route path="/empty" element={<LaunchWebsiteWithAWS />} />
      <Route path="/aws-amplify" element={<LaunchWebsiteWithAWS />} />
      <Route path="/aws-route53" element={<AwsRoute53 />} />
      <Route path="/aws-budget-alert" element={<AwsBudgetAlert />} />
      <Route path="/coding-environment" element={<SetUpWsl />} />
    </Routes>
  )

  const mainPageStyles = {
    padding: window.innerWidth >= 1200 ? '10px' : 'inherit'
  }

  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container={true} rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 7 }}>
          <Grid xs={12} md={12} lg={12}>
            <ResponsiveAppBar />
          </Grid>
          <Grid xs={0} md={0} lg={2}>
            {/* <Box display="flex" justifyContent="center"> */}
              <SideDrawer />
            {/* </Box> */}
          </Grid>
          <Grid direction="column" wrap='nowrap' xs={12} md={12} lg={10} sx={mainPageStyles}>
            {mainPageRoutes}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
