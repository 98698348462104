import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { CodeBlock, dracula } from "react-code-blocks";

/**
 * Using the following 2 packages
 * https://github.com/remarkjs/react-markdown : render markdown
 * https://github.com/remarkjs/remark-gfm : can render tables & html
 **/

const SetUpWsl = () => {
  const styles = {
    textAlign: 'start',
    height: '100%',
    width: '100%',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const refStyle = {
    backgroundColor: "#909176",
  }

  const codeBlock = (codeText, language, showLineNumbers) => {
    return <CodeBlock
      text={codeText}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={dracula}
    />
  }

  const createReactAppCode = `npx create-react-app my-app
cd my-app
npm start
`

  return (
    <Grid>
      <Box>
        <div className="content" style={styles}>
          <div>
            <h1>Set up WSL (Windows Subsystem for Linux)</h1>
            <p>Microsoft official document website has a list of steps, follow them for basic setup:
              <br />
              <a href="https://docs.microsoft.com/en-us/windows/wsl/install-win10" target="_blank"> https://docs.microsoft.com/en-us/windows/wsl/install-win10</a>
            </p>
            <br />
            <h4>Notes:</h4>
            <ul>
              <li>For choice of Linux distribution, recommend Ubuntu 20.04.</li>
              <li>Recommend to install Windows Terminal, as it is easy & useful for navigating through multiple terminals and split panels.</li>
              <li>If your Ubuntu bash does not start with bash color after installing Ubuntu to WSL:
                <ul>
                  <li>
                    Start Ubuntu bash, open the file &quot;
                    <span style={refStyle}>~/.bashrc</span>
                    &quot; and uncomment this line &quot;
                    <span style={refStyle}>#force_color_prompt=yes</span>&quot;
                    <br />
                    Then restart(close & reopen) Ubuntu terminal, or enter the command line &quot;source ~/.bashrc&quot; to take immediate effect.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <h4>Use WSL 1 or WSL 2?</h4>
            <p>Comparison of WSL 1 and WSL 2 is listed here:
              <a href="https://docs.microsoft.com/en-us/windows/wsl/compare-versions" target="_blank"> https://docs.microsoft.com/en-us/windows/wsl/compare-versions</a>
            </p>
            <h5>
              I highly recomment you to use WSL2 and clone your working repo inside Linux OS (e.g. in root directory ~/ or ~/dev) for greater performance.
            </h5>
            <div>As from the document states that
              <br />
              &quot;
              <span style={refStyle}>
                WSL 2 architecture outperforms WSL 1 in several ways, with the exception of performance across OS file systems
              </span>
              &quot;
              <a href="https://docs.microsoft.com/en-us/windows/wsl/compare-versions#performance-across-os-file-systems" target="_blank"> [ref]</a>
              <br />
              But if you insists to put your project root directory in Windows e.g. C:\Users\*\Project.
              <br />
              I would recommend you to use WSL1, as it will build faster with WSL1 than WSL2 if you run your project in Windows directory.
              <br />
              <br />
              But why? if you just want to have quicker access to the folder (e.g. copy paste files from windows to your repo)
              <br />
              You can redirect to your wsl folder with prefix path:
              <span style={refStyle}> \\wsl$\Ubuntu-20.04\root</span>,
              <br />
              or if for other purpose, you can clone another repo in your desired directory.
              <br />
              <br />
              To change WSL versions, command line:&nbsp;
              <ul>
                <li>
                  <span style={refStyle}>
                    wsl --list --verbose
                  </span>
                  &nbsp;&nbsp;(to list out the WSL distro name, state, and WSL version)
                </li>
                <li>
                  <span style={refStyle}>
                    wsl --set-version &quot;distribution name&quot; &quot;versionNumber&quot;
                  </span>
                  &nbsp;&nbsp;(change a distro WSL version, e.g. wsl --set-version Ubuntu-20.04 1)
                </li>
              </ul>
              <br />
            </div>
            <br />
            <hr />
            <h1>Editors:</h1>
            <p>
              Recommend to use VSCode (VisualStudio code), as it is easy to use and it has lots of useful extensions.
              <br />
              Installation step is in their official website:
              <a href="https://code.visualstudio.com/docs/remote/wsl" target="_blank"> https://code.visualstudio.com/docs/remote/wsl</a>
            </p>
            <br />
            <hr />
            <h1>Install NodeJs</h1>
            <p>
              Recommend to use nvm(Node Version Manager), as it allows to install & switch between different versions of NodeJs.
              <br />
              Installation step of nvm:
              <a href="https://github.com/nvm-sh/nvm#installing-and-updating" target="_blank"> https://github.com/nvm-sh/nvm#installing-and-updating</a>
              Usage of nvm:
              <a href="https://github.com/nvm-sh/nvm#usage" target="_blank"> https://github.com/nvm-sh/nvm#usage</a>
            </p>
            <p>
              After installed latest version of NodeJs with nvm, you can now start a react project with command line:
              <a href="https://reactjs.org/docs/create-a-new-react-app.html#create-react-app" target="_blank"> [reference]</a>
              <br />
              {codeBlock(createReactAppCode, 'bash', false)}
              {/* <span style={refStyle}>
                npx create-react-app my-app
                <br />
                cd my-app
                <br />
                npm start
              </span> */}
              <br />
            </p>
            <p>
              Recommend to install eslint and prettier plugin for auto lint/style check and fix:
              <br />
              Installation step:
              <a href="https://medium.com/how-to-react/config-eslint-and-prettier-in-visual-studio-code-for-react-js-development-97bb2236b31a" target="_blank">
                https://medium.com/how-to-react/config-eslint-and-prettier-in-visual-studio-code-for-react-js-development-97bb2236b31a
              </a>
            </p>
            <br />
            <br />
          </div>
        </div >
      </Box>
    </Grid>
  )
}

export default SetUpWsl;
