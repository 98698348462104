import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { close, selectShowSideDrawer } from '../../redux/slices/drawerSlice';

import { useTheme } from '@mui/material/styles';
import { HashLink } from 'react-router-hash-link';

// icons
import { ReactComponent as AwsAmplifySvg } from '../../icons/svg/aws_amplify_svg.svg';
import { ReactComponent as AwsRoute53Svg } from '../../icons/svg/aws_route_53_svg.svg';
import PaidIcon from '@mui/icons-material/Paid';
import SettingsIcon from '@mui/icons-material/Settings';

// ui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const awsPages = [
  {
    string: 'AWS Amplify',
    link: 'aws-amplify#top',
    icon: <AwsAmplifySvg />
  }, {
    string: 'AWS route53',
    link: 'aws-route53#top',
    icon: <AwsRoute53Svg />
  }, {
    string: 'AWS Budget Alert',
    link: 'aws-budget-alert#top',
    icon: <PaidIcon />
  }
];

const environmentPages = [
  {
    string: 'Window Env Setup',
    link: 'coding-environment#top',
    icon: <SettingsIcon />
  }
]

const SideDrawer = () => {
  // https://stackoverflow.com/questions/69494901/how-to-style-the-paper-of-a-drawer-in-mui
  // paper props

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const showSideDrawer = useAppSelector(selectShowSideDrawer);

  //choose the screen size 
  const handleResize = () => {
    // console.log(theme.breakpoints.up('md'))
    if (window.innerWidth >= 1200) {
      // if (theme.breakpoints.up('md')) {
      dispatch(close())
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const clickListItem = () => {
    if (window.innerWidth < 1200) {
      // if (theme.breakpoints.up('md')) {
      dispatch(close())
    }
  }

  const listLinkItem = (page) => (
    // <HashLink key={page.string} to={`/${page.link}`}>
    // good to pass in HashLink as a component, so it will use the parent                                                                         components style
    <ListItem key={page.string} disablePadding>
      <ListItemButton component={HashLink} to={`/${page.link}`} sx={{ width: theme.drawerWidth }} onClick={clickListItem}>
        <ListItemIcon>
          {page.icon}
        </ListItemIcon>
        <ListItemText primary={page.string} />
      </ListItemButton>
    </ListItem>
    // </HashLink>
  )

  const drawerListBox = () => (
    <Box sx={{ overflow: 'auto' }}>
      <List>
        <ListItemText primary={'AWS'} />
        {awsPages.map((page, index) => (
          listLinkItem(page)
        ))}
      </List>
      <Divider />
      <List>
        <ListItemText primary={'Environment'} />
        {environmentPages.map((page, index) => (
          listLinkItem(page)
        ))}
      </List>
    </Box>
  )

  return (
    <Grid>
      <Box sx={{ display: 'flex', maxWidth: '10%' }}>
        <Drawer
          variant="permanent"
          sx={{
            width: theme.drawerWidth,
            // flexShrink: 0,
            // [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
            display: { xs: 'none', md: 'none', lg: 'flex' }
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              // boxShadow: 'none !important',
              outline: 12,
              outlineColor: 'red',
              width: 2 / 12
            }
          }}
        >
          <Toolbar />
          {drawerListBox()}
        </Drawer>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="temporary"
          open={showSideDrawer}
          sx={{
            width: theme.drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
            display: { xs: 'flex', md: 'flex', lg: 'none' }
          }}
          hideBackdrop={true}
        >
          <Toolbar />
          {drawerListBox()}
        </Drawer>
      </Box>
      {/* } */}
    </Grid>
  );
}

// function mapStateToProps(state) {
//   console.log(state)
//   return { state }
//   // const { todos } = state
//   // return { todoList: todos.allIds }
// }

// export default connect(mapStateToProps)(SideDrawer)

export default SideDrawer;
