import React from "react";

import { useAppDispatch } from '../../redux/hooks';
import { onOff } from '../../redux/slices/drawerSlice';

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as DogeLaugh } from '../../icons/svg/doge_laugh_svg.svg';
import s3prefixUrl from '../../icons/awsS3prefixUrl';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { HashLink } from 'react-router-hash-link';

const pages = [
  {
    string: 'aws',
    link: 'aws-amplify#top'
  }, {
    string: 'windows env setup',
    link: 'coding-environment#top'
  }
];

const ResponsiveAppBar = () => {
  const dispatch = useAppDispatch();
  const awsS3url = s3prefixUrl.gifPath;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [avatarGifDisplay, setAvatarGifDisplay] = React.useState(awsS3url + '/corgi_dance.gif');

  const handleSideBarDisplay = (event) => {
    dispatch(onOff());
  };

  const handleCloseNavMenu = () => {
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  }

  const handleCloseUserMenu = (event, gif) => {
    if (gif !== 'backdropClick') setAvatarGifDisplay(gif);
    setAnchorElUser(null);
  };

  const DogeIcon = (props) => {
    return (
      <SvgIcon component={DogeLaugh} {...props} />
    );
  }

  const linearGradient = 'linear-gradient(90deg, rgba(121,116,9,0.3865255574886205) 0%, rgba(10,103,91,0.4313434846594888) 51%, rgba(56,180,10,0.5321838207939426) 96%)';

  const pictureList = [
    {
      name: 'honey_dog',
      gifPath: awsS3url + '/honey_dog.gif'
    }, {
      name: 'shiba_swim',
      gifPath: awsS3url + '/shiba_swim.gif'
    }, {
      name: 'corgi_dance',
      gifPath: awsS3url + '/corgi_dance.gif'
    }, {
      name: 'corgi_shake',
      gifPath: awsS3url + '/corgi_shake.gif'
    }, {
      name: 'meow_glass',
      gifPath: awsS3url + '/meow_glass.gif'
    }, {
      name: 'meow_licking',
      gifPath: awsS3url + '/meow_licking.gif'
    }, {
      name: 'meow_massage',
      gifPath: awsS3url + '/meow_massage.gif'
    }, {
      name: 'shiba_drink_water_smile',
      gifPath: awsS3url + '/shiba_drink_water_smile.gif'
    }, {
      name: 'shiba_wink',
      gifPath: awsS3url + '/shiba_wink.gif'
    }
  ]

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: linearGradient }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <DogeIcon sx={{ display: { xs: 'none', md: 'none', lg: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'none', lg: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            HowTo?
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleSideBarDisplay}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <DogeIcon sx={{ display: { xs: 'flex', md: 'flex', lg: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex', lg: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            HowTo?
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' } }}>
            {pages.map((page) => (
              <HashLink key={page.string} style={{ textDecoration: "none", color: "white" }} to={`/${page.link}`}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.string}
                </Button>
              </HashLink>
            ))}
          </Box>

          <Box>
            <Tooltip title="Change Avatar">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Cute" src={avatarGifDisplay} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {pictureList.map((setting) => (
                <MenuItem key={setting.name} onClick={(event) => handleCloseUserMenu(event, setting.gifPath)}>
                  <Avatar alt={setting.name} src={setting.gifPath} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
