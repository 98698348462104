import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';

export interface SideDrawerState {
  show: boolean
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SideDrawerState = {
  show: false,
  status: 'idle',
};

export const sideDrawerSlice = createSlice({
  name: 'sideDrawer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    open: (state, props) => {
      // console.log(props)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.show = true;
    },
    close: (state) => {
      state.show = false;
    },
    setShow: (state, props) => {
      state.show = props.payload;
    },
    onOff: (state) => {
      state.show = !state.show;
    }
  }
});

export const { open, close, setShow, onOff } = sideDrawerSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectShowSideDrawer = (state: RootState) => state.sideDrawer.show;

export default sideDrawerSlice.reducer;
