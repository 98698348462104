import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { CodeBlock, dracula } from "react-code-blocks";

/**
 * Using the following 2 packages
 * https://github.com/remarkjs/react-markdown : render markdown
 * https://github.com/remarkjs/remark-gfm : can render tables & html
 **/

const AwsBudgetAlert = () => {
  const styles = {
    textAlign: 'start',
    height: '100%',
    width: '100%',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const innerStyles = {
    // paddingLeft: '100px',
    // paddingTop: '100px',
    // textAlign: 'start',
    // display: 'flex',
    // justifyContent:'center',
    // alignItems:'center'
  }

  const codeStyle = {
    whiteSpace: "pre-wrap",
    backgroundColor: "#f9f9f0",
    border: "double",
  }
  const codeText = ` {
    "Sid": "AWSBudgetsSNSPublishingPermissions",
    "Effect": "Allow",
    "Principal": {
      "Service": "budgets.amazonaws.com"
    },
    "Action": "SNS:Publish",
    "Resource": "your topic ARN"
  },`;

  const codeBlock = (codeText, language, showLineNumbers) => {
    return <CodeBlock
      text={codeText}
      language={language}
      showLineNumbers={showLineNumbers}
      theme={dracula}
    />
  }

  return (
    <Grid>
      <Box>
        <div className="content" style={styles}>
          <div style={innerStyles}>
            <h1>Set up your AWS Budget Alert!</h1>
            <hr />
            <h4>Why?</h4>
            <ul>
              <li>To monitor/track your hosted website or AWS services cost.</li>
              <li>To prevent budget exceed your expectation.</li>
              <li>To prevent disasters, e.g. there is cases when user forgot to stop running services
                <br />
                while trying new things out, or created expensive more services(extra database) unintentionally.
              </li>
            </ul>
            <p>
              Setting up AWS budget alert allows user to get first hand infomation and dig into it immediately.
            </p>
            <br />
            <h4>AWS console</h4>
            <p>First sign in AWS console here:
              <a href="https://signin.aws.amazon.com"> https://signin.aws.amazon.com</a>
            </p>
            <br />
            <h4>Amazon SNS</h4>
            <p>
              Search Simple Notification Service in the services search bar.
              <br />
              Click into it, if it does not leads you to the Dashboard, click on Start with an overview.
            </p>
            <br />
            <p id="section1">
              1) On the dashboard navigation pane, choose Topics. Then click Create topic.
            </p>
            <div id="section2">
              2) Inside Details:
              <ul>
                <li>Type: choose Standard (for SMS alerts)</li>
                <li>Name: give it a name, e.g. CostAlert</li>
                <li>Display name: SMS display name, e.g. AWScostAlert</li>
              </ul>
            </div>
            <div id="section3">
              3) In Access policy, choose Advanced.
              <br />&nbsp;&nbsp;&nbsp;
              In the policy text field, after &quot;Statement&quot;: [, add the following text:
              {codeBlock(codeText, 'json', true)}
              Replace &quot;AWSBudgetsSNSPublishingPermissions&quot; with a string. The Sid must be unique within the policy.
              <br />
              e.g. AWSBudgets-notification-1
              <br />
              Then click &quot;Create topic&quot;
            </div>
            <p id="section4">
              4) Inside the topic you just created, under Details.
              <br />&nbsp;&nbsp;&nbsp;
              Copy the ARN string, then hit Edit, navigate to Access policy right below,
              <br />&nbsp;&nbsp;&nbsp;
              in &quot;Resource&quot;: &quot;your topic ARN&quot;
              <br />&nbsp;&nbsp;&nbsp;
              replace your topic ARN with the ARN you have just copied.
              <br />
              Hit Save changes, your topic should be ready.
            </p>
            <div id="section5">
              5) Create a subscription to add in your phone number as an endpoint to receive SMS
              <br />&nbsp;&nbsp;&nbsp;
              On the dashboard navigation pane, choose Subscriptions. Then click Create subscription.
              <br />&nbsp;&nbsp;&nbsp;
              Inside Details:
              <ul>
                <li>Topic ARN: choose the ARN you just created</li>
                <li>Protocol: Alert endpoint, choose SMS</li>
                <li>Endpoint: insert your mobile number</li>
              </ul>
              Hit Create subscription, your should receive a SMS confirmation soon.
            </div>
            <br />
            <p id="section6">
              6) Navigate to Billing Dashboard (under your name, or search in services Billing)
            </p>
            <br />
            <div id="section7">
              7) In the navigation pane, go to Budgets, then hit Create budget.
              Inside Create a budget:
              <ul>
                <li>Step 1: choose Cost Budget</li>
                <li>Step 2: Set your budget</li>
                <ul>
                  <li>Name: give the budget a name, e.g. websiteBudget</li>
                  <li>Period: choose Monthly</li>
                  <li>Budget effective dates: Recurring budget</li>
                  <li>Specify your monthly budget: Fixed</li>
                  <li>Budgeted amount: enter your expected budget, can be $10</li>
                </ul>
                <li>Step 3: Configure thresholds</li>
                <ul>
                  <li>Set threshold based on:: Actual cost</li>
                  <li>Alert threshold: percentage of your budget amount to receive alert, e.g. 30% = $3</li>
                  <li>Email recipients: enter your email for the budget alert</li>
                  <li>Amazon SNS: Enter the ARN you have just created here to receive SMS alert</li>
                </ul>
                <li>Step 4: Confirm budget - Create</li>
              </ul>
              Your Budget Alert is ready!
            </div>
            <br />
            <br />
          </div>
        </div >
      </Box>
    </Grid>
  )
}

export default AwsBudgetAlert;
