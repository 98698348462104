import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import s3prefixUrl from '../../../icons/awsS3prefixUrl';

/**
 * Using the following 2 packages
 * https://github.com/remarkjs/react-markdown : render markdown
 * https://github.com/remarkjs/remark-gfm : can render tables & html
 **/

const AwsRoute53 = () => {
  const styles = {
    textAlign: 'start',
    height: '100%',
    width: '100%',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  const innerStyles = {
    // paddingLeft: '100px',
    // paddingTop: '100px',
    // textAlign: 'start',
    // display: 'flex',
    // justifyContent:'center',
    // alignItems:'center'
  }

  const imageStyle = {
    float: 'left',
    // width: '142px',
    // height: '142px',
  }

  const awsS3url = s3prefixUrl.awsImagePath;

  return (
    <Grid>
      <Box>
        <div className="content" style={styles}>
          <div style={innerStyles}>
            <h1>Register Domain with AWS Route53</h1>
            <hr />
            <h4>AWS console</h4>
            <p>First sign in AWS console here:
              <a href="https://signin.aws.amazon.com"> https://signin.aws.amazon.com</a>
            </p>
            <br />
            <h4>AWS Route53</h4>
            <p>Search AWS Route53 in the services search bar then register your domain in minutes!</p>
            <img src={awsS3url + '/AWS_route53_1.png'} />
            <br />
            <br />
            <p id="section1"> 1) Click register domain, then check for an available domain~
              <br />
              After you found an available domain name and happy with it,
              fill out your contact details and process to purchase.
            </p>
            <img src={awsS3url + '/AWS_route53_2.png'} />
            <br />
            <br />
            <p id="section2"> 2) Once purchased, registering your domain might take up some time.
              <br />
              You can check if your domain is ready by going to Registered domains or Pending requests.
            </p>
            <img src={awsS3url + '/AWS_route53_3.png'} />
            <br />
            <p> Now you can connect your registered domain to AWS Amplify website!</p>
            <HashLink to="/aws-amplify#section4"> Add registered domain to Amplify</HashLink>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div >
      </Box>
    </Grid>
  )
}

export default AwsRoute53;
